import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'login',
    // canActivate: [AuthGuard],
    loadChildren: './auth/login/login.module#LoginPageModule'
  },
  {
    path: 'create',
    // canActivate: [AuthGuard],
    loadChildren: './auth/create/create.module#CreatePageModule'
  },
  {
    path: 'recovery',
    // canActivate: [AuthGuard],
    loadChildren: './auth/recovery/recovery.module#RecoveryPageModule'
  },
  {
    path: 'reset_password',
    loadChildren: './auth/reset/reset.module#ResetPageModule'
  },
  {
    path: 'misdatos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/misdatos/misdatos.module').then(m => m.MisdatosPageModule)
  },
  {
    path: 'facturaspendientes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./facturaspendientes/facturaspendientes.module').then(m => m.FacturaspendientesPageModule)
  },
  {
    path: 'documentos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/documentos/documentos.module').then(m => m.DocumentosPageModule)
  },
  {
    path: 'estadocuentas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/estadocuentas/estadocuentas.module').then(m => m.EstadocuentasPageModule)
  },
  {
    path: 'situacioncrediticia',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/situacioncrediticia/situacioncrediticia.module').then(m => m.SituacionCrediticiaModule)
  },
      {
    path: 'entregas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/entregas/entregas.module').then(m => m.EntregasPageModule)
  }
  // {
  //   path: 'facturaspagadas',
  //   canActivate: [AuthGuard],
  //   loadChildren: './facturaspagadas/facturaspagadas.module#FacturaspagadasPageModule'
  // }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
