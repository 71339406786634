import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private toastService: ToastService) { }

  canActivate(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.toastService.presentToast('La sesion ha expirado.');
      this.router.navigate(['login']);
      return false;
    }
  }
}
