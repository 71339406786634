import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  appPages = [{
    title: 'Home',
    url: '/home',
    icon: 'home'
  },
  {
    title: 'Mis Datos',
    url: '/misdatos',
    icon: 'list'
  },
  {
    title: 'Cartola',
    url: '/cartola',
    icon: 'list'
  }
  ];

  constructor() {
  }

}
