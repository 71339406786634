import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// CLIENTE HTTP
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// MASCARA PARA RUT DE INICIO DE SESION
import { NgxMaskIonicModule } from 'ngx-mask-ionic';

// IDIOMA ESP
import localeEs from '@angular/common/locales/es-CL';
import localeEsExtra from '@angular/common/locales/extra/es-CL';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'Es', localeEsExtra);

// INTERCEPTOR
import { AuthHelpers } from './services/interceptor.service';
import { CacheInterceptor } from './services/cache.interceptor';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskIonicModule.forRoot(),
    // FORZAMOS LA VISUALIZACION DE IOS INDEPENDIENTE DEL SO
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // SET INTERCEPTOR SERVICE
    { provide: HTTP_INTERCEPTORS, useClass: AuthHelpers, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    // SET IDIOMA ESPANOL
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
