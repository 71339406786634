import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingController, AlertController, NavController } from '@ionic/angular';
import { finalize, delay, tap } from 'rxjs/operators';

@Injectable()
export class AuthHelpers implements HttpInterceptor {
    contador = 0;
    contadortoken = 0;
    constructor(private loadingCtrl: LoadingController, private _navcontroller: NavController, private alertCtrl: AlertController) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.contador++;
        if (this.contador === 1 && !request.url.includes('usuarios/mensajes')) {
            // this.presentLoading();
        }
        if (request.url.includes('login') || request.url.includes('tipos_cuentas')) {
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
        }
        return next.handle(request).pipe(
            tap(
                () => { },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.contadortoken++;
                            if (this.contadortoken === 1) {
                                this.alertToken();
                            }
                        }
                    }
                }
            ),
            delay(500),
            finalize(() => {
                this.contador--;
                if (this.contador === 0) {
                    // this.dismissLoader();
                }
            })
        );
    }

    async alertToken() {
        const _alert = await this.alertCtrl.create({
            mode: 'ios',
            header: 'Aviso',
            message: 'La sesion ha expirado.',
            backdropDismiss: false,
            keyboardClose: false,
            buttons: [
                {
                    text: 'Iniciar Sesion',
                    handler: () => {
                        localStorage.clear();
                        this._navcontroller.navigateRoot(['/login']);
                    },
                },
            ],
        });
        _alert.present();
    }

    async presentLoading() {
        const _loader = await this.loadingCtrl.create({
            spinner: 'circles',
            mode: 'ios',
            message: 'Cargando...',
        });
        await _loader.present();
        return true;
    }

    async dismissLoader() {
        const top = await this.loadingCtrl.getTop();
        if (top !== undefined) {
            await this.loadingCtrl.dismiss();
        }
    }
}
